// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-pro/css/all.min.css";
@import "/assets/styles/utils.scss";
@import "/assets/styles/animate.scss";
@import "/assets/styles/owl.carousel.scss";
@import "/assets/styles/magnific-popup.scss";
@import "/assets/styles/nice-select.scss";
@import "/assets/styles/jquery.mCustomScrollbar.min.scss";
@import "/assets/styles/theme.scss";
@import "/assets/styles/responsive.scss";
@import "/assets/styles/main.scss";



.input-file-container {
  position: relative;
  width: 100%;
  max-width: 320px;
}

.fup .input-file-trigger {
  display: block;
  padding: 14px 45px;
  background: rgba($neway-blue-rgb, 0.8);
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
}

.fup .input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}

.fup .input-file:hover + .input-file-trigger,
.fup .input-file:focus + .input-file-trigger,
.fup .input-file-trigger:hover,
.fup .input-file-trigger:focus {
  background: #34495E;
  color: #FFF;
}

.file-return {
  margin: 0;
}

.file-return:not(:empty) {
  margin: 1em 0;
}

.js .file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}

.fup .file-return:not(:empty):before {
  content: "Votre fichier : ";
  font-style: normal;
  font-weight: normal;
}

.inside_nav {
  text-align: center;
  background: #f9fafe;
  ul {
    padding: 0;
    display: inline-block;

    li {
      display: inline-block;
      list-style: none;
      margin: 30px 10px;
      //a{
      //  border: 1px solid $neway-blue;
      //  border-radius: 5px;
      //  padding: 10px;
      //  color: $neway-blue;
      //  font-size: 16px;
      //  &:hover{
      //    border: 1px solid rgba($neway-blue-rgb , 0.7);
      //    color: rgba($neway-blue-rgb , 0.7);
      //    background: rgba($neway-blue-rgb , 0.2);;
      //
      //  }
      //
      //}
    }
  }
}



.row-content{
  background: #FFF;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 50px;
}

.blog_area.sec_pad .row-content p{
  font-size: 14px;
}

.cont-logos{
  .card{
    transition: all 2000ms ease;
    border: none;
    margin-bottom: 20px;
    z-index: 10;
    .card-header{
      border-radius: 5px 5px 0 0 ;
      border: 2px solid $neway-blue;
      padding: 10px;
      text-align: center;
      img{
        filter: grayscale(100%);
      }
    }
    .card-block{
      border-radius: 0 0 5px 5px;
      border-color: $neway-blue;
      border-style: solid;
      border-width: 0 2px 2px 2px;
      h5{
        background: $neway-blue;
        color: #FFF;
        text-align: center;
        padding: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 700;
      }
      .card-text{
        padding: 0 20px 20px 20px;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        min-height: 100px;
      }
    }
    &:hover{
      transition: all 200ms ease;

      transform: scale(1.1);
      z-index: 20;
      img{
        filter: grayscale(0%);
      }
    }
  }
}

#tab_filter .item {
  width: 100%;
  height: 130px;
}
.job_listing .listing_tab .list_item {
  display: table;
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(12, 0, 46, 0.04);
  padding: 20px 45px 20px 20px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.2s linear;
}
img.icon-grp{
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox-margin label {
  margin-right: 30px;
  margin-left: 10px;
}

.form-group input[type="checkbox"] {
  display: none;
}

.form-group input[type="checkbox"] + .btn-group > label span {
  width:15px;
}

.form-group input[type="checkbox"] + .btn-group > label span:first-child {
  display: none;
}
.form-group input[type="checkbox"] + .btn-group > label span:last-child {
  display: inline-block;
}

.form-group input[type="checkbox"]:checked + .btn-group > label span:first-child {
  display: inline-block;
}
.form-group input[type="checkbox"]:checked + .btn-group > label span:last-child {
  display: none;
}
.mon-arriere-plan {
  background-image: url(../../public/images/abstract-techno-background-with-connecting-lines.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  z-index: -2;
}


.btn-perso {
  color: #fff;
  background-color: #24479C;
  border-color: #24479C;
}
.option-container {
  display: flex;
}

.option {
  margin-right: 10px;
}
.blanc p {
  color: white;
}
.blanc label {
  color: white;
}
.no-hover:hover{
 color: #FFFFFF !important;
}
span.bleu-land{
  color:#1BEAFF;
}

.bleu {
  color:#3D77BD
}
